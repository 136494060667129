export const BASE_URL_ASSETS = process.env.NEXT_PUBLIC_BASE_URL_ASSETS || ''
export const URL_APP_HOME = process.env.NEXT_PUBLIC_URL_APP_HOME || ''
export const URL_APP_AREA = process.env.NEXT_PUBLIC_URL_APP_AREA || ''
export const CUSTOMER_SERVICE_PHONE_NO = process.env.NEXT_PUBLIC_CS_PHONE_NO || ''
export const URL_WHATSAPP = `https://api.whatsapp.com/send?phone=${CUSTOMER_SERVICE_PHONE_NO}`
export const WA_DITJEN_PKTN = process.env.NEXT_PUBLIC_WA_DITJEN_PKTN || ''
export const APPLINK_CHAT = process.env.NEXT_PUBLIC_APPLINK_CHAT || ''
export const ENV = process.env.NEXT_PUBLIC_ENV || ''
export const ENABLE_DATADOG = process.env.NEXT_PUBLIC_ENABLE_DATADOG === 'true'
export const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || ''
export const DATADOG_SITE = process.env.NEXT_PUBLIC_DATADOG_SITE || ''
export const DATADOG_SESSION_SAMPLE_RATE = process.env.NEXT_PUBLIC_DATADOG_SESSION_SAMPLE_RATE || ''
export const DATADOG_API_KEY = process.env.NEXT_PUBLIC_DATADOG_API_KEY || ''
export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || ''
export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION || ''
export const DATADOG_LOG_HTTP_CODE = process.env.NEXT_PUBLIC_DATADOG_LOG_HTTP_CODE || ''
export const APP_BASE_URL = process.env.NEXT_PUBLIC_APP_BASE_URL || ''
export const ASSET_PREFIX = process.env.NEXT_PUBLIC_ASSET_PREFIX || ''
export const ASTRO_SITE_BASE_URL = process.env.NEXT_PUBLIC_ASTRO_SITE_BASE_URL || ''
export const ENABLE_REACT_QUERY_DEV_TOOL =
  process.env.NEXT_PUBLIC_ENABLE_REACT_QUERY_DEV_TOOL === 'true'
export const DEFAULT_GLOBAL_LOCATION_ID =
  Number(process.env.NEXT_PUBLIC_DEFAULT_GLOBAL_LOCATION_ID) || 0
export const TOBACCO_MIN_AGE = Number(process.env.NEXT_PUBLIC_TOBACCO_MIN_AGE) || 0

export const MIDTRANS_CLIENT_KEY = process.env.NEXT_PUBLIC_MIDTRANS_CLIENT_KEY || ''
export const MIDTRANS_SCRIPT_URL = process.env.NEXT_PUBLIC_MIDTRANS_SCRIPT_URL || ''
export const XENDIT_CLIENT_KEY = process.env.NEXT_PUBLIC_XENDIT_CLIENT_KEY || ''
export const XENDIT_SCRIPT_URL = process.env.NEXT_PUBLIC_XENDIT_SCRIPT_URL || ''
export const DISABLED_PAYMENTS = process.env.NEXT_PUBLIC_DISABLED_PAYMENTS || ''
export const ENABLE_INTL = process.env.NEXT_PUBLIC_ENABLE_INTL === 'true'
export const SUPPORTED_LOCALE = ['en', 'id']
export const LINK_CHAT_CS = 'https://api.whatsapp.com/send?phone=6281388888907'
export const LINK_BANNER_INSTALL_APP = 'https://astroid.link/astrolitebanner'
export const ASTRO_BLOG_BASE_URL = process.env.NEXT_PUBLIC_ASTRO_BLOG_BASE_URL || ''
export const USE_HTML_IMAGE_TAG = process.env.NEXT_PUBLIC_USE_HTML_IMAGE_TAG === 'true'
export const BRANCH_KEY = process.env.NEXT_PUBLIC_BRANCH_KEY || ''
export const BRANCH_ENABLE = process.env.NEXT_PUBLIC_BRANCH_ENABLE === 'true'

export const ASTRO_EMAIL = 'astrocare@astronauts.id'

export const META_TITLE = 'Belanja Kebutuhan Harian Sampai dalam Hitungan Menit | ASTRO'

export const META_DESCRIPTION =
  'Situs Belanja Kebutuhan Harian Online dengan Harga Terjangkau dan Kualitas Terbaik. Pesan Kebutuhan Harian di ASTRO Pasti Sampai dalam Hitungan Menit'

export const SESSION_COOKIE_NAME = process.env.SESSION_COOKIE_NAME || 'astronauts'
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || ''

export const REFRESH_AUTH_TOKEN_IN_DAYS =
  Number(process.env.NEXT_PUBLIC_REFRESH_AUTH_TOKEN_IN_DAYS) || 0
